import React from 'react';
import '../App.css'; // Importing CSS for styling

const Services = () => {
  return (
    <section className="services" id="services">
      <div className="services-grid">
        <div className="service-item">
          <h3>Product and Brand</h3>
          <p>Showcase your business with stunning product and brand photography.</p>
        </div>
        <div className="service-item">
          <h3>Weddings</h3>
          <p>Capture your special day with timeless, elegant photos that tell your story.</p>
        </div>
        <div className="service-item">
          <h3>Portraits</h3>
          <p>Professional and relaxed portrait photography for individuals and families.</p>
        </div>
        <div className="service-item">
          <h3>Graphics and Digital Content</h3>
          <p>High-quality graphics and digital content tailored to your needs.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
