import React from 'react';
import Masonry from 'react-masonry-css';
import '../App.css';

const MasonryLayout = () => {
    
  const images = [
    { src: 'images/portfolio/wedding/6@0.3x.png', alt: 'Image 1' },
    { src: 'images/portfolio/wedding/2@0.3x.png', alt: 'Image 2' },
    { src: 'images/portfolio/wedding/3@0.3x.png', alt: 'Image 3' },
    { src: 'images/portfolio/wedding/4@0.3x.png', alt: 'Image 4' },
    { src: 'images/portfolio/wedding/12@0.3x.png', alt: 'Image 5' },
    { src: 'images/portfolio/wedding/11@0.3x.png', alt: 'Image 6' },
    { src: 'images/portfolio/wedding/7@0.3x.png', alt: 'Image 8' },
    { src: 'images/portfolio/wedding/8@0.3x.png', alt: 'Image 9' },
    { src: 'images/portfolio/wedding/9@0.3x.png', alt: 'Image 10' },
    { src: 'images/portfolio/wedding/10@0.3x.png', alt: 'Image 11' },
    { src: 'images/portfolio/wedding/13@0.3x.png', alt: 'Image 12' },
    { src: 'images/portfolio/wedding/a3@0.3x.png', alt: 'Image 14' },
    { src: 'images/portfolio/wedding/a4@0.3x.png', alt: 'Image 15' },
    { src: 'images/portfolio/wedding/a6@0.3x.png', alt: 'Image 17' },
    { src: 'images/portfolio/wedding/a7@0.3x.png', alt: 'Image 18' },
  ];

  const breakpointColumnsObj = {
    default: 7,
    1100: 2,
    700: 1
  };

  return (
    <section className="portfolio" id="gallery">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, index) => (
          <div key={index} className="masonry-item">
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </Masonry>
    </section>
  );
};

export default MasonryLayout;
